import * as React from 'react';
import clsx from 'clsx';

import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { TopPageCustomSections } from 'components/TopPageCustomSections/TopPageCustomSections';
import { ProductListViewProps } from 'lib/themes/themes';
import baseStyles from 'components/Themes/CoolProfessional/base.module.css';
import { BookingWidgetSectionHeader } from 'components/BookingWidgetSectionHeader/BookingWidgetSectionHeader';

import { KeyVisualView } from './KeyVisualView';
import styles from './ProductListView.module.css';

const ProductListView = ({
  bannerImageUrls,
  bannerTitle,
  bannerDescription,
  productSummaries,
}: ProductListViewProps) => {
  return (
    <div className={clsx(baseStyles['color-txt'], baseStyles['color-bg'])}>
      <KeyVisualView
        imageUrls={bannerImageUrls}
        bannerTitle={bannerTitle}
        bannerDescription={bannerDescription}
      />

      <div className={styles['main-content']}>
        <TopPageCustomSections />
        <BookingWidgetSectionHeader text="PLAN MENU" />
      </div>

      <div className={baseStyles['base-inner']}>
        <ul className={styles['item']}>
          {productSummaries.map((productSummary, idx) => (
            <ProductSummaryCard key={idx} product={productSummary} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductListView;
